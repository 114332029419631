import Proj1 from "../../images/projects/studysesh.jpg";
import Proj2 from "../../images/projects/tvshowsearch.jpg";
import Proj3 from "../../images/projects/gpacalculator.jpg";
import Proj4 from "../../images/projects/artx.jpg";
import Proj5 from "../../images/projects/portfoliov1.jpg";
import Proj6 from "../../images/projects/videosearch.jpg";
import Proj7 from "../../images/projects/omnifood.jpg";
import Proj8 from "../../images/projects/nextours.jpg";
import Proj9 from "../../images/projects/coachfinder.jpg";
import Proj10 from "../../images/projects/minishop.jpg";

export const item = [
  {
    url: "https://studytimerbyruss.netlify.app/",
    title: "Study Sesh",
    description: `Study Sesh is a personal web project that aims to enhance
    the study experience for all types of students. This
    user-friendly web app offers a convenient timer feature,
    allowing students to effectively manage their study
    sessions. With Study Sesh, users can easily customize study
    and break times, enabling them to maintain a structured and
    productive study routine. Whether students are preparing for
    exams, working on assignments, or seeking to improve focus,
    Study Sesh ensures they stay on track and make the most of
    their study sessions.`,
    src: Proj1,
    tools: ["React", "CSS", "HTML", "Git", "Netlify"],
  },
  {
    url: "https://showsearchbyruss.netlify.app/",
    title: "Show Search",
    description: `Show Search is a personal web app project that utilizes an API to provide an enhanced TV show search experience. By leveraging the power of the API, users can search for any keyword and instantly retrieve a list of TV show posters. Clicking on a poster reveals valuable information such as genres and a brief summary, enabling users to make informed decisions about the shows they want to explore. Show Search combines the convenience of an API with a user-friendly interface to deliver a seamless and engaging TV show discovery process.`,
    src: Proj2,
    tools: ["Javascript", "React", "CSS", "TVMaze API", "Netlify"],
  },
  {
    url: "https://gpacalculatorbyruss.netlify.app/",
    title: "GPA Calcu",
    description: `
    GPA Calculator is a personal project that simplifies the process of calculating GPA for students, following the formula used by De La Salle University and adaptable to other universities with similar criteria. This user-friendly web app enables users to add, delete, and manage their subjects, allowing for easy tracking of grades and overall GPA. With the ability to reset progress, users can start fresh whenever needed. GPA Calculator provides a convenient solution for students to accurately monitor and evaluate their academic performance.`,
    src: Proj3,
    tools: ["React", "CSS", "Netlify"],
  },
  {
    url: "https://russhenson.netlify.app",
    title: "Portfolio v2",
    description: `Welcome to my personal web portfolio, Version 2! This project holds a special place in my heart as it represents one of my earliest and most significant endeavors. Built solely with vanilla JavaScript, HTML, and CSS, it marks the beginning of my journey into web development. Through this project, I have gained valuable experience, expanded my skills, and fostered a deep passion for creating impactful web experiences. `,
    src: Proj5,
    tools: ["React", "CSS", "Javascript", "Netlify"],
  },
];

export const allItem = [
  {
    url: "https://artxbyruss.netlify.app/",
    title: "ArtX",
    tools: ["React", "CSS", "Javascript", "Netlify"],
    src: Proj4,
  },
  {
    url: "https://russhenson.netlify.app",
    title: "Portfolio v2",
    tools: ["React", "CSS", "Javascript", "Netlify"],
    src: Proj5,
  },
  {
    url: "https://github.com/russhenson/flowerstore-crud",
    title: "Minishop",
    src: Proj10,
    tools: ["Vue", "MySQL", "Node.js", "Vercel", "CSS"],
  },
  {
    url: "https://gpacalculatorbyruss.netlify.app/",
    title: "GPA Calcu",
    src: Proj3,
    tools: ["React", "CSS", "Netlify"],
  },
  {
    url: "https://studytimerbyruss.netlify.app/",
    title: "Study Sesh",
    src: Proj1,
    tools: ["React", "CSS", "HTML", "Git", "Netlify"],
  },
  {
    url: "https://showsearchbyruss.netlify.app/",
    title: "Show Search",
    src: Proj2,
    tools: ["Javascript", "React", "CSS", "TVMaze API", "Netlify"],
  },
  {
    url: "https://videosearchbyruss.netlify.app/",
    title: "Video Search",
    src: Proj6,
    tools: ["Javascript", "Google PSE API", "jQuery", "CSS", "Netlify"],
  },
  {
    url: "https://omnifoodbyruss.netlify.app/",
    title: "Omnifood",
    src: Proj7,
    tools: ["Javascript", "CSS", "HTML", "Netlify"],
  },
  {
    url: "https://natoursbyruss.netlify.app/",
    title: "Natours",
    src: Proj8,
    tools: ["Javascript", "CSS", "SASS"],
  },
  {
    url: "https://github.com/russhenson/Coach-Finder-Project-Using-Vue",
    title: "Coach Finder",
    src: Proj9,
    tools: ["Vue", "Git", "HTML", "CSS", "JavaScript"],
  },
];
